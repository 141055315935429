import React from 'react'
import ProjectList from '../../../features/Admin/ProjectList'

function AdminDashboard() {
  return (
    <div>
        <ProjectList/>
    </div>
  )
}

export default AdminDashboard