import React, { Suspense } from "react";
import { Routes } from "react-router-dom";
import { Route } from "react-router";
import ItemPage from "../pages/ItemPage";
import MyNotifications from "../pages/MyNotifications";
import PrivateViewPage from "../pages/PrivateViewPage";
import PublicViewPage from "../pages/PrivateViewPage";
import envVariables from "../utils/config.json";
import AdminDashboard from "../pages/Admin/AdminDashboard";

const AddItemsPage = React.lazy(() => import("../pages/AddItems"));
const ControlCenter = React.lazy(() => import("../pages/ControlCenter"));
const MyMentions = React.lazy(() => import("../pages/MyMentions"));
const PrivateView = React.lazy(() => import("../features/CustomViewPage"));
const PageNotFound = React.lazy(()=>  import("../pages/PageNotFound"))
const MyPowerBiDashboard = React.lazy(() => import("../pages/PowerbiDashboards/MyPowebiDashboard"));
const ProjectPowerBiDashboard = React.lazy(() => import("../pages/PowerbiDashboards/ProjectPowerbiDashboard"));
const AdminPowerBiDashboard = React.lazy(() => import("../pages/PowerbiDashboards/AdminPowerbiDashboard"));
const CreateProject = React.lazy(() => import("../pages/Admin/CreateProject"));
const EditProject = React.lazy(() => import("../pages/Admin/EditProject"));

const Navigations = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<ControlCenter />} />
        <Route path="/item/new" element={<AddItemsPage />} />
        <Route path="/control-center" element={<ControlCenter />} />
        <Route path="/item-page" element={<ItemPage />} />
        <Route path="/item/edit/:code" element={<ItemPage />} />
        <Route path="/my-mentions" element={<MyMentions />} />
        <Route path="/my-notifications" element={<MyNotifications />} />
        <Route path="/admin" element={<AdminDashboard/>} />
        <Route path="/admin/create" element={<CreateProject/>} />
        <Route path="/admin/edit/:id" element={<EditProject/>} />
        <Route path="/private/:id" element={<PrivateViewPage />} />
        <Route path="/public/:id" element={<PublicViewPage />} />
        {process.env.REACT_APP_IS_PBI_ENABLED || envVariables.REACT_APP_IS_PBI_ENABLED ? <Route path="/my-dashboard" element={<MyPowerBiDashboard />} /> : null}
        {process.env.REACT_APP_IS_PBI_ENABLED || envVariables.REACT_APP_IS_PBI_ENABLED ? <Route path="/project-dashboard" element={<ProjectPowerBiDashboard />} /> : null}
        {process.env.REACT_APP_IS_PBI_ENABLED || envVariables.REACT_APP_IS_PBI_ENABLED ? <Route path="/delivery-dashboard" element={<AdminPowerBiDashboard />} /> : null}
        <Route path="/page-not-found" element={<PageNotFound />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Suspense>
  );
};

export default Navigations;
