import { MRT_RowSelectionState } from "mantine-react-table";
import { Dispatch, SetStateAction } from "react";
export interface TableDataItem {
  id: string;
  title: string;
  project: string;
  owner: any;
  targetDate: string;
  labels: string;
  status: string;
}

export interface RaidViewProps {
  dashboardName: string;
  onClickItem: Dispatch<SetStateAction<any>>;
  rowSelection: MRT_RowSelectionState;
  setRowSelection: Dispatch<SetStateAction<MRT_RowSelectionState>>;
  onClickShowMore: number;
  setOnClickShowMore: Dispatch<SetStateAction<any>>;
  searchText: string;
  onClickSearch?: number;
}

export interface FilterBody {
  pageNumber: number;
  searchText: string;
  skip: number | null;
  pageSize: number;
  itemType: number | null;
  targetDate: {
    startDate: string;
    endDate: string;
  } | null;
  containers: number[] | null;
  owners: number[] | null;
  status: number[] | null;
  labels: number[] | null;
  sortBy: string | null;
  sortOrder: string | null;
  AllResutlsForPage: boolean | false;
  Paging: boolean | true;
}

export interface FilterBodyFilters {
  containers: number[];
  targetDate: {
    startDate: string;
    endDate: string;
  } | null;
  owners: number[];
  status: number[];
  labels: number[];
  assignedByUsers: Array<number>;
}

export interface TableMetaData {
  currentPage: number;
  totalPages: number;
  pageSize: number;
  totalCount: number;
  hasPrevious: boolean;
  hasNext: boolean;
  items: TableItem[];
}

export interface TableItem {
  id: number;
  code: string;
  itemType: string;
  title: string;
  project: string;
  priority: string;
  owner: string;
  assignedBy: string;
  assignedDate: string;
  createdDate: string;
  targetDate: string;
  labels: string[];
  statuses: {
    name: string;
    colorCode: string;
  };
}

export interface RaidType {
  key: number;
  value: string;
}

export interface WidgetCount {
  containers: number[] | null;
  searchText: string;
  targetDate: {
    startDate: string;
    endDate: string;
  } | null;
  owners: number[] | null;
  status: number[] | null;
  labels: number[] | null;
}

export enum ListCallOrigin {
  SEE_MORE,
  FILTER,
  TYPE,
  SORT,
  REFRESH,
}
