import { Flex, createStyles, rem } from "@mantine/core";

const useSideNavigationStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[7]
        : theme.colors.dark[7],

    "& .mantine-18fbar1": {
      height: "length",
      overflow: "scroll",
    },
  },
  nav: {
    width: 80,
  },

  collapseNav: {
    width: 250,
  },
  SectionHeaders: {
    color: "rgba(255, 255, 255, 0.4)",
    fontSize: 16,
    fontWeight: "normal",
  },
  privateFiltersText: {
    fontSize: 14,
  },
  publicFiltersText: {
    fontSize: 14,
  },
  spaceWrapper: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[7]
        : theme.colors.dark[7],
    // "& .mantine-Accordion-control":{
    //   display: "none"
    // },
    "& .mantine-Accordion-label": {
      paddingTop: 0,
    },
    "& .mantine-Accordion-chevron": {
      marginTop: -15,
    },
    "& .mantine-hp9ccp": {
      "&:hover": {
        backgroundColor:
          theme.colorScheme === "dark"
            ? theme.colors.dark[7]
            : theme.colors.dark[7],
      },
      color:
        theme.colorScheme === "dark"
          ? theme.colors.gray[6]
          : theme.colors.gray[6],
    },
    "& .mantine-1h0npq1": {
      border: "none",
    },
  },
  contentWrapper: {
    overflowY: "scroll",
    height: "100vh",
    padding: 0,

    scrollbarWidth: "none",
    "::-webkit-scrollbar": {
      display: "none" /* Safari and Chrome browsers */,
    },
  },
  spaceAvatar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .mantine-153fvzf": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.dark[7],
      borderColor: theme.white,
    },
  },
  privateViewTag: {
    width: 14,
    height: 14,
    borderRadius: 7,
    marginTop: 3,
    backgroundColor: "#5798BC"
  },
  publicViewTag: {
    width: 14,
    height: 14,
    borderRadius: 7,
    marginTop: 3,
    backgroundColor: "#1A4B67"
  },
  myViewTag: {
    width: 14,
    height: 14,
    borderRadius: 7,
    marginTop: 3,
    backgroundColor: "#5798BC"
  },
  projectViewTag: {
    width: 14,
    height: 14,
    borderRadius: 7,
    marginTop: 3,
    backgroundColor: "#1A4B67"
  },
  OrganizationName: {
    display: "flex",
    alignItems: "center",
    // justifyContent: "center",
  },
  paddingZero: {
    padding: 0,
  },
  paddingFive: {
    paddingLeft: 5,
    paddingTop: 5,
  },
  topPaddingFifteen: {
    paddingTop: 15,
    cursor: "pointer"
  },
  topPaddingTen: {
    paddingTop: 5,
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  flexStart: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  headerSubTitle: {
    fontSize: 12,
  },
  dashboardText: {
    fontSize: 14,
  },
  collapsedBadgeWrap: {
    position: "relative",
  },
  expandNotification: {
    backgroundColor: theme.colors.red[7],
    color: theme.white,
  },
  expandNotificationCollapse: {
    position: "absolute",
    marginTop: -15,
    marginLeft: -10,
  },
  footerText: {
    color: theme.colors.blue[8],
    marginLeft: 10,
  },
  itemWrapper: {
    borderRadius: 8,
    "&:hover": {
      backgroundColor: "rgba(89, 172, 232, 0.09);",
      cursor: "pointer",
      borderLeft: "5px solid #AEDDFF",
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    marginBottom: 10,
  },
  activeItemWrapper: {
    borderRadius: 8,
    backgroundColor: "rgba(89, 172, 232, 0.09);",
    cursor: "pointer",
    borderLeft: "5px solid #AEDDFF",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    marginBottom: 10,
  },
  logoutWrapper: {
    width: 250,
    "&: hover": {
      backgroundColor: "rgba(89, 172, 232, 0.09);",
      cursor: "pointer",
      borderLeft: "5px solid #AEDDFF",
      borderRadius: 5,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    }
  },
  logoutSection: {
    color: "#ffffff",
    height: 50,
    paddingTop: 8,
    paddingLeft: 3,
    paddingBottom: 0,

    
  },
  navLink: {
   textDecoration: "none",
   padding: "10px",
    marginBottom: 10,
  color: theme.colors.gray[6],
 },
 adminNavLink: {
  textDecoration: "none",
 color: theme.colors.gray[6],
}

}));

export default useSideNavigationStyles;
