import {
  Navbar,
  Container,
  Grid,
  Avatar,
  Text,
  ActionIcon,
  Image,
  Accordion,
  Badge,
  Collapse,
  Flex,
  Button,
} from "@mantine/core";

import useSideNavigationStyles from "./styles";
import { SideNavigationProps } from "./types";

import ChevronsRight from "../../assets/svg/ChevronsRight.svg";
import ChevronLeft from "../../assets/svg/ChevronLeft.svg";
import File from "../../assets/icons/trackly.png";
import { useEffect, useState, memo } from "react";
import DividerComponent from "../common/Divider";
import { Loader } from "@mantine/core";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import { IconLogout } from "@tabler/icons-react";
import { useMsal } from "@azure/msal-react";
import { updateEditMode, useViews } from "../../contexts/view.context";
import Modal from "../common/Modal";
import { clearFilterByDashbordNameAsync, useFilterContext } from "../../contexts/filter.context";
import ViewLink from "./ViewLink/ViewLink";
import PowerbiLink from "./PowerbiLink/PowerbiLink";
import envVariables from "../../utils/config.json";
import { useLoggedInUserContext } from "../../contexts/userDetail.context";
import { usePowerbiDashboardContext } from "../../contexts/powerbiDashboards.context";

const SideNavigation = ({
  collapsed,
  onClickCollapsed,
  privateFilters,
  publicFilters,
  dashboards,
  ProjectName,
  name,
  position,
  avatarURL,
  powerbiUserDashboards,
  powerbiAdminDashboards,
 adminDashboards
}: SideNavigationProps) => {
  const { classes, cx } = useSideNavigationStyles();
  const location = useLocation();
  const [opened, { toggle }] = useDisclosure(false);
  const { instance } = useMsal();
  const { state: filterState, dispatch } = useFilterContext();

  const baseNavWidth = collapsed ? 80 : 250;

  const [dashboardAccordionValue, setDashboardAccordionValue] = useState<
    string | null
  >("dashboardAccordion");
  const [privateAccordionValue, setPrivateAccordionValue] = useState<
    string | null
  >(null);
  const [publicAccordionValue, setPublicAccordionVValue] = useState<
    string | null
  >();
  const [publicAdminAccordionValue, setPublicAdminAccordionVValue] = useState<
    string | null
  >();
  const [powerbiDashboardAccordionValue, setPowerbiDashboardAccordionValue] = useState<
  string | null
>();
  const { dispatch: dispatchViews, state: viewState } = useViews();
  const [modalOpen, setModalOpen] = useState(false);
  const [pathName, setPathName] = useState('');
  const navigate = useNavigate();
  const { userDetails } = useLoggedInUserContext();

  useEffect(() => {
    if (collapsed) {
      setDashboardAccordionValue("dashboardAccordion");
      setPrivateAccordionValue("privateAccordion");
      setPublicAccordionVValue("publicAccordion");
      setPowerbiDashboardAccordionValue("powerbiDashboardAccordion")
    }
  }, [collapsed]);

  const { fetchMyPowerBiDashboard, fetchProjectPowerBiDashboard, fetchAdminPowerBiDashboard } = usePowerbiDashboardContext();

  useEffect(()=>{
    if ((process.env.REACT_APP_IS_PBI_ENABLED || envVariables.REACT_APP_IS_PBI_ENABLED) && userDetails.isAdmin) {
      fetchAdminPowerBiDashboard();
    }else if((process.env.REACT_APP_IS_PBI_ENABLED || envVariables.REACT_APP_IS_PBI_ENABLED) && !userDetails.isAdmin){
      fetchMyPowerBiDashboard();
      fetchProjectPowerBiDashboard();
    }
  },[userDetails.isAdmin])

  useEffect(() => {
    (publicFilters && publicFilters.length > 0) && setPublicAccordionVValue("publicAccordion");
    (privateFilters && privateFilters.length > 0) && setPrivateAccordionValue("privateAccordion");
  }, [publicFilters,privateFilters]);


  const logout = () => {
    try {
      // if browser is firefox, just logout()  
      if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
        instance.logout({
          account: instance.getActiveAccount(),
        });
        return;
      }

      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const DashBoards = () => {
    const lastIndex = dashboards?.data.length - 1;
    return (
      <>
        {dashboards?.data.map((dashboard, index) => {
          return (
            <Link onClick={(e) => ClickHandler(e, `${dashboard?.route}`)} to={dashboard?.route} style={{ textDecoration: "none" }}>
              {lastIndex === index ?
                <DividerComponent
                  color="#677079"
                  style={{ marginBottom: "30px" }}
                /> : null}
              <Grid
                columns={12}
                key={index}
                className={
                  dashboard?.route === location.pathname ||
                    (location.pathname === "/" &&
                      dashboard.route === "/control-center")
                    ? classes.activeItemWrapper
                    : classes.itemWrapper
                }
              >
                {!collapsed && <Grid.Col xs={1}></Grid.Col>}
                <Grid.Col
                  xs={collapsed ? 12 : 3}
                  className={collapsed ? classes.flexCenter : classes.flexStart}
                >
                  <Image
                    src={dashboard?.icon}
                    style={{ width: 25, height: 25 }}
                  />
                  {collapsed && dashboard?.notifications ? (
                    <div className={classes.collapsedBadgeWrap}>
                      <Badge
                        size="xs"
                        className={cx(
                          classes.expandNotification,
                          collapsed && classes.expandNotificationCollapse
                        )}
                      >
                        {dashboard?.notifications}
                      </Badge>
                    </div>
                  ) : null}
                </Grid.Col>
                {!collapsed && (
                  <>
                    <Grid.Col xs={6} style={{ paddingLeft: 0 }}>
                      <Text c="white" className={classes.dashboardText}>
                        {dashboard.name}
                      </Text>
                    </Grid.Col>
                    <Grid.Col xs={1}>
                      {dashboard?.notifications ? (
                        <Badge size="xs" className={classes.expandNotification}>
                          {dashboard?.notifications}
                        </Badge>
                      ) : null}
                    </Grid.Col>
                    <Grid.Col xs={1}></Grid.Col>
                  </>
                )}
              </Grid>
            </Link>
          );
        })}
      </>
    );
  };

  const ClickHandler = (event: any, path: string) => {
    if (viewState.editMode) {
      setPathName(path);
      event.preventDefault();
      setModalOpen(true);
    }
  };

  const PrivateFilters = () => {
    return (
      <>
        {privateFilters?.map((privateFilter, index) => {
          return (
            <ViewLink publicView={privateFilter} location={location.pathname} collapsed={collapsed} index={index} ClickHandler={ClickHandler} type="private" />
          );
        })}
      </>
    );
  };

  const PublicFilters = () => {
    return (
      <>
        {publicFilters?.filter((view) => view.isOwned).map((publicView, index) => {
          return (
            <ViewLink publicView={publicView} location={location.pathname} collapsed={collapsed} index={index} ClickHandler={ClickHandler} type="public" />);
        })}
        <DividerComponent
          color="#677079"
          style={{ marginBottom: "15px" }}
        />
        {publicFilters?.filter((view) => !view.isOwned).map((publicView, index) => {
          return (
            <ViewLink publicView={publicView} location={location.pathname} collapsed={collapsed} index={index} ClickHandler={ClickHandler} type="public" />);
        })}
      </>
    );
  };




  const PowerbiDashboardView = () => {
    if (userDetails.isAdmin) {
      return(
        <PowerbiLink icon={powerbiAdminDashboards?.data[0].icon} name={powerbiAdminDashboards?.data[0].name} route={powerbiAdminDashboards?.data[0].route} location={location.pathname} collapsed={collapsed} ClickHandler={ClickHandler} index={0} />
        );
    }else{
      return (
        <>
        {
          powerbiUserDashboards?.data.map((data,index) => {
            return(
              <PowerbiLink icon={data.icon} name={data.name} route={data.route} location={location.pathname} collapsed={collapsed} ClickHandler={ClickHandler} index={0}/>
              );
          }
        )}
        </>
      );
    }
    
  };

  const AdminDashboardView = () => {
      return (
        <>
        {
          adminDashboards?.data.map((data,index) => {
            return(
              <PowerbiLink icon={data.icon} name={data.name} route={data.route} location={location.pathname} collapsed={collapsed} ClickHandler={ClickHandler} index={index}/>
              );
          }
        )}
        </>
      );
    
  };

  return (
    <>
      <Navbar
        className={cx(
          classes.wrapper,
          collapsed ? classes.nav : classes.collapseNav
        )}
        width={{ base: baseNavWidth }}
        height={"100%"}
      >
        <Navbar.Section>
          <Container my="md" className={classes.topPaddingFifteen}>
            <Grid columns={12}>
              {name ? (
                <Grid.Col xs={collapsed ? 12 : 3} onClick={toggle}>
                  <Avatar color="blue" radius="sm" src={avatarURL}>
                    {name.slice(0, 1)}
                  </Avatar>
                </Grid.Col>
              ) : (
                <Grid.Col style={{ height: 54, paddingLeft: 70 }}>
                  <Loader variant="dots" />
                </Grid.Col>
              )}
              {!collapsed && name && (
                <Grid.Col
                  xs={9}
                  className={cx(classes.paddingZero, classes.paddingFive)}
                  onClick={toggle}
                >
                  <Grid.Col xs={12} className={classes.paddingZero}>
                    <Text c="white">{name}</Text>
                  </Grid.Col>
                  <Grid.Col xs={12} className={classes.paddingZero}>
                    <Text c="white" className={classes.headerSubTitle}>
                      {position}
                    </Text>
                  </Grid.Col>
                </Grid.Col>
              )}

              <Collapse in={opened} transitionDuration={250} transitionTimingFunction="linear" className={classes.logoutWrapper} onClick={() => logout()}>
                <Grid columns={12} className={classes.logoutSection}>
                  {!collapsed && <Grid.Col xs={1}></Grid.Col>}
                  <Grid.Col xs={collapsed ? 12 : 3} className={cx(
                    collapsed ? classes.flexCenter : classes.flexStart
                  )}>
                    <IconLogout size={28} color="#ffffff" />
                  </Grid.Col>

                  {!collapsed && name && <Grid.Col xs={6} style={{ marginLeft: -10 }}><Text size={14}>Log Out</Text></Grid.Col>}
                </Grid>
              </Collapse>

              <div style={{ position: "relative", left: baseNavWidth - 20 }}>
                <ActionIcon
                  size="sm"
                  variant="filled"
                  color="blue"
                  radius="xl"
                  onClick={onClickCollapsed}
                >
                  {!collapsed ? (
                    <Image src={ChevronLeft} />
                  ) : (
                    <Image src={ChevronsRight} />
                  )}
                </ActionIcon>
              </div>

            </Grid>
          </Container>
        </Navbar.Section>
        <div className={classes.contentWrapper}>
          <Navbar.Section>
            <Accordion
              variant="default"
              className={classes.spaceWrapper}
              value={dashboardAccordionValue}
              onChange={setDashboardAccordionValue}
              transitionDuration={800}
            >
              <Accordion.Item value="dashboardAccordion">
                <Accordion.Panel>
                  <DashBoards />
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </Navbar.Section>
          <Navbar.Section>
            <Accordion
              variant="default"
              className={classes.spaceWrapper}
              value={privateAccordionValue}
              onChange={setPrivateAccordionValue}
              transitionDuration={800}
            >
              <Accordion.Item value="privateAccordion">
                {collapsed ? (
                  <DividerComponent
                    color="#27333F"
                    style={{ marginBottom: "15px" }}
                  />
                ) : (
                  <Accordion.Control>
                    <Text className={classes.SectionHeaders}>
                      Private Views
                    </Text>
                  </Accordion.Control>
                )}
                <Accordion.Panel>
                  <PrivateFilters />
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </Navbar.Section>
          <Navbar.Section>
            <Accordion
              variant="default"
              className={classes.spaceWrapper}
              value={publicAccordionValue}
              onChange={setPublicAccordionVValue}
              transitionDuration={800}
            >
              <Accordion.Item value="publicAccordion">
                {collapsed ? (
                  <DividerComponent
                    color="#27333F"
                    style={{ marginBottom: "15px" }}
                  />
                ) : (
                  <Accordion.Control>
                    <Text className={classes.SectionHeaders}>
                      {"Public Views"}
                    </Text>
                  </Accordion.Control>
                )}
                <Accordion.Panel>
                  <PublicFilters />
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </Navbar.Section>
          <div>
        <Navbar.Section>
            <Accordion
              variant="default"
              className={classes.spaceWrapper}
              value={publicAdminAccordionValue}
              onChange={setPublicAdminAccordionVValue}
              transitionDuration={800}
            >
              <Accordion.Item value="publicAccordion">
                {collapsed ? (
                  <DividerComponent
                    color="#27333F"
                    style={{ marginBottom: "15px" }}
                  />
                ) : (
                  <Accordion.Control>
                    <Text className={classes.SectionHeaders}>
                      {"Admin"}
                    </Text>
                  </Accordion.Control>
                )}
                <Accordion.Panel>
                  <Link to={"admin/create"} className={classes.adminNavLink}>
                  <AdminDashboardView/>
                  </Link>
                </Accordion.Panel>
                
              </Accordion.Item>
            </Accordion>
          </Navbar.Section>
        </div>
          {process.env.REACT_APP_IS_PBI_ENABLED || envVariables.REACT_APP_IS_PBI_ENABLED ?
            <Navbar.Section>
              <Accordion
                variant="default"
                className={classes.spaceWrapper}
                value={powerbiDashboardAccordionValue}
                onChange={setPowerbiDashboardAccordionValue}
                transitionDuration={800}
              >
                <Accordion.Item value="powerbiDashboardAccordion">
                  {collapsed ? (
                    <DividerComponent
                      color="#27333F"
                      style={{ marginBottom: "15px" }}
                    />
                  ) : (
                    <Accordion.Control>
                      <Text className={classes.SectionHeaders}>
                        {"Dashboards"}
                      </Text>
                    </Accordion.Control>
                  )}
                  <Accordion.Panel>
                    <PowerbiDashboardView />
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            </Navbar.Section>
            :
            null}
        </div>
        <div>
          <Navbar.Section>
            <Container my="md" className={classes.topPaddingFifteen}>
              <Grid columns={12}>
                <Grid.Col xs={12} className={classes.flexCenter}>
                  <Image src={File} style={{ width: 25, height: 25 }} />
                  {!collapsed && (
                    <Text className={classes.footerText}>{ProjectName}</Text>
                  )}
                </Grid.Col>
              </Grid>
            </Container>
          </Navbar.Section>
        </div>
      </Navbar>
      <Modal
        opened={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        title="Redirect from edit mode?"
        size={428}
      >
        <Text>You are in edit mode. Are you sure you want to redirect?</Text>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
          <Button variant="outline"
            color="gray"
            compact
            onClick={() => setModalOpen(false)}
          >
            Keep Editing
          </Button>
          <Button compact
            style={{ marginLeft: 5 }}
            color="red"
            onClick={async () => {
              pathName != "/control-center" && await clearFilterByDashbordNameAsync(dispatch, filterState, location.pathname)
              updateEditMode(dispatchViews, { type: "editMode", payload: false });
              setModalOpen(false);
              navigate(pathName);
            }}>
            Close and Redirect
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default SideNavigation;
