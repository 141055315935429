import { createContext, useContext, useReducer } from "react";
import { getAccessToken } from "../services/authService";
import { getProjectFilterList } from "../services/filterService/projectFilter";
import { useMsal } from "@azure/msal-react";
import { ProjectListProps } from "../components/Filters/ProjectFilter";
import { getDetailedContainers } from "../services/adminService/createAndEditProject";

interface ProjectState {
  Projects: any;
  detailedProjects:any;
  status: "idle" | "loading" | "failed";
  detailedStatus: "idle" | "loading" | "failed";
  detailedProjectsPagination: APIDetailedProjectPaginationData
}

interface APIDetailedProjectPaginationData {
  currentPage: number;
  totalPages: number;
  pageSize: number;
  totalCount: number;
  hasPrevious: boolean;
  hasNext: boolean
}
interface APIProjectData {
  key: number;
  label: string;
  isSelect?: boolean;
  children?: APIProjectData[];
}

interface ProjectListInputProps {
  data: APIProjectData[];
}

type Action =
  | { type: "FETCH_DATA_PENDING" }
  | { type: "FETCH_DATA_FULFILLED"; payload: any }
  | { type: "FETCH_DATA_REJECTED" }
  | { type: "FETCH_DETAILED_DATA_PENDING" }
  | { type: "FETCH_DETAILED_DATA_FULFILLED"; payload: any }
  | { type: "FETCH_DETAILED_DATA_REJECTED" };

interface ContextValue extends ProjectState {
  fetchProjectList: () => Promise<void>;
  fetchDetailedProjectList: (SearchText: string, Paging: boolean, PageNumber: number, PageSize: number, AllResutlsForPage: boolean)=>Promise<void>;
}

const ProjectContext = createContext<ContextValue | undefined>(undefined);

type ProjectProviderProps = { children: React.ReactNode };

const initialState: ProjectState = {
  Projects: [],
  detailedProjects:[],
  detailedProjectsPagination:{
    currentPage: 0,
    totalPages: 0,
    pageSize:8,
    totalCount:0,
    hasPrevious:false,
    hasNext: false
  },
  status: "idle",
  detailedStatus: "idle",
};

const reducer = (state: ProjectState, action: Action): ProjectState => {
  switch (action.type) {
    case "FETCH_DATA_PENDING":
      return { ...state, status: "loading" };
    case "FETCH_DATA_FULFILLED":
      return { ...state, status: "idle", Projects: action.payload };
    case "FETCH_DATA_REJECTED":
      return { ...state, status: "failed" };
    case "FETCH_DETAILED_DATA_PENDING":
      return { ...state, detailedStatus: "loading" };
    case "FETCH_DETAILED_DATA_FULFILLED":
      return { ...state, 
            detailedStatus: "idle", 
            detailedProjects: action.payload?.items,
            detailedProjectsPagination: {
              currentPage: action.payload?.currentPage,
              totalPages: action.payload?.totalPages,
              pageSize: action.payload?.pageSize,
              totalCount: action.payload?.totalCount,
              hasPrevious: action.payload?.hasPrevious,
              hasNext: action.payload?.hasNext,
            }
          };
    case "FETCH_DETAILED_DATA_REJECTED":
      return { ...state, detailedStatus: "failed" };
    default:
      return state;
  }
};

function ProjectProvider({ children }: ProjectProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { instance, accounts } = useMsal();

  const fetchProjectList = async () => {
    dispatch({ type: "FETCH_DATA_PENDING" });
    try {
      const accessToken = await getAccessToken(instance, accounts[0]);
      const response = await getProjectFilterList(accessToken);
      const data = response?.data;
      dispatch({ type: "FETCH_DATA_FULFILLED", payload: data });
    } catch (error) {
      dispatch({ type: "FETCH_DATA_REJECTED" });
    }
  };

  const fetchDetailedProjectList = async (
    SearchText: string,
    Paging:boolean,
    PageNumber: number,
    PageSize: number,
    AllResutlsForPage:boolean
  ) => {
    dispatch({ type: "FETCH_DETAILED_DATA_PENDING" });
    try {
      const accessToken = await getAccessToken(instance, accounts[0]);
      const response = await getDetailedContainers(
        accessToken,
        SearchText,
        Paging,
        PageNumber,
        PageSize,
        AllResutlsForPage
      );
      const data = response?.data;
      dispatch({ type: "FETCH_DETAILED_DATA_FULFILLED", payload: data });
    } catch (error) {
      dispatch({ type: "FETCH_DETAILED_DATA_REJECTED" });
    }
  };

  return (
    <ProjectContext.Provider value={{ ...state, fetchProjectList, fetchDetailedProjectList }}>
      {children}
    </ProjectContext.Provider>
  );
}

const useProjectContext = (): ContextValue => {
  const context = useContext(ProjectContext);
  if (!context) {
    throw new Error("useDataContext must be used within a DataProvider");
  }
  return context;
};

export { useProjectContext, ProjectProvider };
