import axios from "axios";

import { OwnerOptionsAPIItem } from "../../components/Filters/OwnerFilter/types";
import envVariables from "../../utils/config.json";
import { ClientTypesAPIItem, ContainerTypesAPIItem, UsersAPIItem } from "../../components/Admin/Projects/CreateProjectForm/type";
import { CreateContainerBodyType } from "../../features/Admin/CreateProject/type";
import { containerAPIItem } from "../../components/Admin/Projects/EditProjectForm/type";
import { UpdateContainerBodyType } from "../../features/Admin/EditProject/type";
import { ContainerDetailedAPIItem } from "../../components/Admin/Projects/ProjectTable/type";

export const getAllOwnersOptions = (
  searchString: string,
  accessToken: any
): Promise<{ data: OwnerOptionsAPIItem[] }> => {
  const bearer = `Bearer ${accessToken}`;
  return axios({
    method: "GET",
    url: `${
      process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL
    }/users`,
    headers: { authorization: bearer },
    params: { SearchText: searchString },
  });
};

export const getAllContainerTypes = (
    accessToken: any
  ): Promise<{ data: ContainerTypesAPIItem[] }> => {
    const bearer = `Bearer ${accessToken}`;
    return axios({
      method: "GET",
      url: `${
        process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL
      }/containerTypes`,
      headers: { authorization: bearer },
    });
  };

  export const getAllClientTypes = (
    accessToken: any
  ): Promise<{ data: ClientTypesAPIItem[] }> => {
    const bearer = `Bearer ${accessToken}`;
    return axios({
      method: "GET",
      url: `${
        process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL
      }/clients`,
      headers: { authorization: bearer },
    });
  };  

  export const getAllUserOptions = (
    accessToken: any
  ): Promise<{ data: UsersAPIItem[] }> => {
    const bearer = `Bearer ${accessToken}`;
    return axios({
      method: "GET",
      url: `${
        process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL
      }/users`,
      headers: { authorization: bearer },
      params: { SearchText: '' },
    });
  };

  export const addContainer = async (
    requestBody: CreateContainerBodyType,
    accessToken: string
  ): Promise<{ data: any }> => {
    const bearer = `Bearer ${accessToken}`;
    
    return axios({
      method: "post",
      maxBodyLength: Infinity,
      url: `${
        process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL
      }/container`,
      headers: {
        authorization: bearer,
      },
      data: requestBody,
    });
  };

  export const getContainer = (
    accessToken: any,
    Id: number
  ): Promise<{ data: containerAPIItem }> => {
    const bearer = `Bearer ${accessToken}`;
    return axios({
      method: "GET",
      url: `${
        process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL
      }/container`,
      headers: { authorization: bearer },
      params: { Id },
    });
  };

  export const getDetailedContainers = (
    accessToken: any,
    SearchText: string,
    Paging:boolean,
    PageNumber: number,
    PageSize: number,
    AllResutlsForPage:boolean
  ): Promise<{ data: ContainerDetailedAPIItem }> => {
    const bearer = `Bearer ${accessToken}`;
    return axios({
      method: "GET",
      url: `${
        process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL
      }/container/details`,
      headers: { authorization: bearer },
      params: { 
      SearchText,
      Paging,
      PageNumber,
      PageSize,
      AllResutlsForPage
       },
    });
  };

  export const updateContainer = async (
    requestBody: UpdateContainerBodyType,
    accessToken: string
  ): Promise<{ data: any }> => {
    const bearer = `Bearer ${accessToken}`;
    
    return axios({
      method: "put",
      maxBodyLength: Infinity,
      url: `${
        process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL
      }/container`,
      headers: {
        authorization: bearer,
      },
      data: requestBody,
    });
  };

