import React from 'react'
import { Table, Pagination, TextInput, Button, Loader } from '@mantine/core';
import { ProjectTableProps } from './type';
import { IconEdit, IconSearch } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { useProjectContext } from '../../../../contexts/project.context';


const ProjectTable = ({
    activePage,
    setPage,
    totalPages,
    searchValue,
    setSearchValue,
    projectData
}:ProjectTableProps)=> {

    const navigate = useNavigate();

    const {
        detailedStatus,
      } = useProjectContext();

    function convertTimestamp(timestampStr: string): string {
        try {
          const dt = new Date(timestampStr);
          const year = dt.getFullYear().toString().slice(-2);
          const month = dt.toLocaleString('en-US', { month: 'short' });
          const day = dt.getDate().toString().padStart(2, '0');
          return `${year}-${month}-${day}`;
        } catch (error) {
          return "Invalid timestamp format";
        }
      }

    const rows = projectData.map((element) => (
        <tr key={element.id}>
          <td>{element.id}</td>
          <td>{element.name}</td>
          <td>{element.managerUserName}</td>
          <td>{element.clientName}</td>
          <td>{element.parentName}</td>
          <td>{element.containerTypeName}</td>
          <td>{convertTimestamp(element.createdOn)}</td>
          <td>{convertTimestamp(element.lastUpdatedOn)}</td>
          <td>
          <Button 
          onClick={()=>navigate(`edit/${element.id}`)} 
          leftIcon={<IconEdit />} variant="white">
            Edit
         </Button>
          </td>
        </tr>
      ));

  return (
    <>
     <TextInput
        icon={<IconSearch size={16} />}
        placeholder="Search Project"
        value={searchValue}
        onChange={(event) => setSearchValue(event.currentTarget.value)} 
        style={{marginBottom:20}}
      />
      <div style={{
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
            justifyContent:'center',
            marginBottom:10
        }}>
         {detailedStatus=='loading'?<Loader variant="dots" />: null}
        </div>
    <Table>
    <thead>
      <tr>
        <th>ID</th>
        <th>Name</th>
        <th>Manager Name</th>
        <th>Client Name</th>
        <th>Parent Name</th>
        <th>Type</th>
        <th>Created Date</th>
        <th>Last Update Date</th>
        <th></th>
      </tr>
    </thead>
    <tbody>{rows}</tbody>
  </Table>
  <Pagination 
    total={totalPages} 
    value={activePage} 
    onChange={setPage}
    style={{marginTop:20}}
    />
  </>
  )
}

export {ProjectTable} 