import React, {useEffect, useState} from 'react'
import ProjectTable from '../../../components/Admin/Projects/ProjectTable'
import Typography from '../../../components/common/Typography'
import { TypographyTypes } from '../../../utils/enums'
import { ProjectTableDataProps } from '../../../components/Admin/Projects/ProjectTable/type';
import { useProjectContext } from '../../../contexts/project.context';
import { Loader } from '@mantine/core';

function ProjectList() {

    const [activePage, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState<number>(3);
    const [searchValue, setSearchValue] = useState<string>('');
    const [projectData, setProjectDate] = useState<ProjectTableDataProps[]>([]);

    const {
        detailedStatus,
        detailedProjects,
        detailedProjectsPagination,
        fetchDetailedProjectList
      } = useProjectContext();

      useEffect(()=>{
        fetchDetailedProjectList(searchValue,true,activePage,6,false);
      },[searchValue,activePage])

      useEffect(()=>{
        setPage(1)
      },[searchValue])

      useEffect(()=>{
        setTotalPages(detailedProjectsPagination?.totalPages)
        setProjectDate(detailedProjects)
      },[detailedProjects,detailedProjectsPagination])

  return (
    <div>
        <Typography type={TypographyTypes.mainHeading} text="Admin Dashboard" style={{marginRight:10}}/>
        <Typography type={TypographyTypes.title} text="Projects" style={{marginBottom:15}} />
        <ProjectTable
          activePage={activePage}
          setPage={setPage}
          totalPages={totalPages}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          projectData={projectData}
        />
    </div>
  )
}

export {ProjectList} 