import React, { useState, useEffect } from "react";
import {
  MRT_RowSelectionState,
  MRT_SortingState,
  MantineReactTable,
  MantineReactTableProps,
} from "mantine-react-table";
import { DataTableProps } from "./type";

export const COLUMN_ORDER = "columnOrder";
export const COLUMN_SIZING = "columnSizing";

const DataTable = ({ ...props }: MantineReactTableProps & DataTableProps) => {

  const calculateMaxHeight = () => {
    const screenHeight = window.innerHeight;
  
    const headerFilterElement = document.getElementById("header-and-filter");
    const headerFilterHeight = headerFilterElement ? headerFilterElement.offsetHeight : 0;
  
    const widgetElement = document.getElementById("widget-section");
    const widgetHeight = widgetElement ? widgetElement.offsetHeight : 0;
  
    const titleElement = document.getElementById("title-section");
    const titleHeight = titleElement ? titleElement.offsetHeight : 0;
  
    const remainingHeight = screenHeight - headerFilterHeight - widgetHeight - titleHeight - 90;  //90dA for the padding and margins that does not get included in the heights.
  
    return remainingHeight + "px";
  };
  
  const [tableMaxHeight, setTableMaxHeight] = useState("");

  useEffect(() => {
    const handleResize = () => {
      const newTableMaxHeight = calculateMaxHeight();
      setTableMaxHeight(newTableMaxHeight);
    };

    // Initial call
    handleResize();

    // Attach resize event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [props.data]);

  const [columnOrder, setColumnOrder] = useState(['code', 'itemType', 'title', 'project', 'priority', 'owner', 'assignedBy', 'assignedDate', 'targetDate', 'statuses', 'labels', 'createdDate']);
  const [columnSizing, setColumnSizing] = useState({
    'code': 60, 
    'itemType': 110, 
    'title': 240, 
    'project':160, 
    'priority':160,
    'owner':120, 
    'assignedBy':120, 
    'assignedDate':135, 
    'targetDate':120, 
    'statuses':110, 
    'labels':100, 
    'createdDate':130
  });

  useEffect(()=>{
    const storedColumnOrder = localStorage.getItem(COLUMN_ORDER);
    if (storedColumnOrder && storedColumnOrder.length > 0) {
      setColumnOrder(JSON.parse(storedColumnOrder));
    }
    const storedColumnSizing = localStorage.getItem(COLUMN_SIZING);
    if (storedColumnSizing && storedColumnSizing.length > 0) {
      setColumnSizing(JSON.parse(storedColumnSizing));
    }
  },[]);

  useEffect(()=>{
    localStorage.setItem(COLUMN_ORDER, JSON.stringify(columnOrder));
  },[columnOrder]);

  useEffect(()=>{
    localStorage.setItem(COLUMN_SIZING, JSON.stringify(columnSizing));
  },[columnSizing]);
  
  return (
    <div style={{height: tableMaxHeight}}>
      <MantineReactTable
        columns={props.columns}
        data={props.data}
        enableBottomToolbar={props.enableBottomToolbar || false}
        enableColumnActions={props.enableColumnActions || false}
        enableTopToolbar={props.enableTopToolbar || false}
        manualSorting
        state={{
          ...props.state, 
          columnOrder: columnOrder,
          columnSizing: columnSizing
        }}
        onSortingChange={props.setSorting}
        enableColumnOrdering
        enableColumnResizing
        enablePagination={false}
        enableStickyHeader
        mantineTableContainerProps={{ sx: {height: tableMaxHeight} }}
        mantineTableBodyRowProps={({ row }) => ({
          sx: {
            cursor: "pointer",
          },
        })}
        initialState={{ columnVisibility: { createdDate: false } }} 
        onColumnOrderChange={(e)=>setColumnOrder(e)}
        onColumnSizingChange={(e:any)=>setColumnSizing(e)}
      />
    </div>
  );
};

export default DataTable;
